export default function ValidatedInput({
    id,
    compId,
    errors,
    style,
    className,
    children,
    ...rest
}) {
    const foundError = errors.filter((e) => e.id === (id || compId)).length > 0;

    return (
        <>
            <div style={style} className={className} {...rest}>
                {children}
            </div>
            <p className="text-[#dc3545] text-13 m-0">
                {foundError && errors.find((e) => e.id === (id || compId)).message}
            </p>
        </>
    )
}