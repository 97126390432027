import { React, useEffect, useState } from "react";
import ValidatedInput from '../components/ValidatedInput';
import Loading from '../components/Loading';
import FormModal from '../components/FormModal';
import FormSuccess from '../components/FormSuccess';
import { Button, Col, Container, Form, FormCheckbox, FormGroup, FormInput, FormRadio, FormSelect, Row } from "shards-react-fork";
import validateEmail from "../utils/validateEmail";
import { user, stripe } from "../services/userService";
import useNewWindow from "../hooks/useNewWindow";


export default function RegistrationPage() {
    const today = new Date();
    const yyyy = today.getFullYear();

    const initRegistrationData = () => ({
        name: "",
        email: "",
        phone: "",
        birthYear: '',
        settlement: '',
        tripId: 1,
        method: 0,
        time: 0,
        billingAddress: '',
        asksForMedal: null,
        completionWithoutReward: false,
    });
    
    const [wantsToSubmit, setWantsToSubmit] = useState(false);
    const [registrationData, setRegistrationData] = useState(initRegistrationData());
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState({ severity: "success", text: "" });
    const [sessionUrl, setSessionUrl] = useState();
    const [code, setCode] = useState();
    



    const validate = () => {
        const errors = [];

        if (registrationData.name === "")
            errors.push({
                id: "registration-name",
                message: "A név kitöltése kötelező!",
            });
        if (registrationData.email === "")
            errors.push({
                id: "registration-email",
                message: "Az e-mail cím kitöltése kötelező!",
            });
        else if (!validateEmail(registrationData.email))
            errors.push({
                id: "registration-email",
                message: "A megadott e-mail cím formátuma nem megfelelő.",
            });
        if (registrationData.phone === "")
            errors.push({
                id: "registration-phone",
                message: "A telefonszám kitöltése kötelező!",
            });
        if (registrationData.birthYear === "")
            errors.push({
                id: "registration-birth-year",
                message: "A születési évszám kitöltése kötelező!",
            });
        if (registrationData.birthYear !== "" && registrationData.birthYear.length < 4)
            errors.push({
                id: "registration-birth-year",
                message: "A születési évszám formátuma hibás!",
            });
        if (parseInt(registrationData.birthYear) < 1900 || parseInt(registrationData.birthYear) > parseInt(yyyy))
            errors.push({
                id: "registration-birth-year",
                message: `A születési évszámnak 1900 és ${yyyy} közé kell esnie!`,
            });
        if (registrationData.settlement === "")
            errors.push({
                id: "registration-settlement",
                message: "A lakóhely kitöltése kötelező!",
            });
        if (registrationData.billingAddress === "")
            errors.push({
                id: "registration-billing-address",
                message: "A számlázási cím kitöltése kötelező!",
            });

        setErrors(errors);
        return errors;
    }

    const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

    const convertCamelCaseObjectPropsToSnakeCase = object => {
        const objectWithSnakeCaseProps = {};
        for (const [key, value] of Object.entries(object)) {
            objectWithSnakeCaseProps[camelToSnakeCase(key)] = value;
        }
        return objectWithSnakeCaseProps;
    }

    const wantsToRegister = () => {
        if (validate().length === 0) setWantsToSubmit(true);
    }

    const clearData = () => setRegistrationData(initRegistrationData());

    const register = async () => {
        setWantsToSubmit(false);
        setIsLoading(true);

        const result = await user(convertCamelCaseObjectPropsToSnakeCase(registrationData));
        if (result.severity === "success") {
            setSessionUrl(result.text?.url);
            setCode(result.text?.code);
            setApiResponse(result)
            console.log(apiResponse)
        }
        else {
            clearData();
            setIsLoading(false);
            setApiResponse(result);
        }
    }
    //time 0-nappal 1 este
    //method - 0-futás 1 túra

    const getPayment = () => {
        if (registrationData.completionWithoutReward) {
            return 1500;
        }
        else if (registrationData.method === 0) {
            const additional = registrationData.time === 1 ? 500 : 0;

            if (registrationData.tripId <= 2) {
                return 3500 + additional;  
            } 
            else if (registrationData.tripId > 1 && registrationData.tripId <= 4) { 
                return 4000 + additional;
            }
            else if (registrationData.tripId === 5) {
                return 4500 + additional;
             }
            else if (registrationData.tripId === 6) {
                return 5000 + additional;
            }
        }
        else if (registrationData.method === 1) {
            const additional = registrationData.time === 1 ? 250 : 0;
            if (registrationData.tripId <= 4) {
                return 2000 + additional;   
            }
            else {
                return 2500 + additional;
        }
        }
    }

    const stripeWindow = useNewWindow(sessionUrl, "Stripe", (result) => {
        setIsLoading(false);
        if (result === "success") setApiResponse({ severity: "success", text: "Sikeres regisztráció!" })
        else setApiResponse({ severity: "error", text: "Sajnáljuk, hiba történt." });
    });

    useEffect(() => {
        if (!stripeWindow?.closed) {
            const interval = setInterval(() => {
                if (stripeWindow?.closed && isLoading) {
                    setIsLoading(false);
                    clearInterval(interval);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [stripeWindow]);

    return (
        <Container>
            <Form>
                {isLoading
                    ? <Loading />
                    : apiResponse.severity === "success" && apiResponse.text
                        ?
                        <FormSuccess
                            code={code}
                            email={registrationData.email}
                            clearData={clearData}
                            setApiResponse={setApiResponse}
                        />
                        : <>
                            <Container>
                                <Row className="text-24 text-center mt-8">
                                    <Col>
                                        Regisztráció
                                    </Col>
                                </Row>
                                <Row key={"r1"} className="my-4 px-2 sm:px-4 py-4 rounded-[10px] bg-[#5A6169] bg-opacity-[.06]">
                                    <Col key={"col1"}>
                                        <Container>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-name`}>
                                                            Név*
                                                        </label>
                                                        <ValidatedInput compId={`registration-name`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-name`}
                                                                placeholder={`Gipsz Jakab`}
                                                                value={registrationData.name}
                                                                onChange={(e) => {
                                                                    const newState = { ...registrationData };
                                                                    newState.name = e.target.value;
                                                                    setRegistrationData(newState);
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-name`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-email`}>
                                                            E-mail cím*
                                                        </label>
                                                        <ValidatedInput compId={`registration-email`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-email`}
                                                                placeholder={`pelda@email.hu`}
                                                                value={registrationData.email}
                                                                onChange={(e) => {
                                                                    const newState = { ...registrationData };
                                                                    newState.email = e.target.value;
                                                                    setRegistrationData(newState);
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-email`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-phone`}>
                                                            Telefonszám*
                                                        </label>
                                                        <ValidatedInput compId={`registration-phone`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-phone`}
                                                                key={`registration-phone`}
                                                                placeholder={"+36301234567"}
                                                                value={registrationData.phone}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    const reg = /^[\d\+]+$/;
                                                                    if (value.match(reg) || !value) {
                                                                        const newState = { ...registrationData };
                                                                        newState.phone = value;
                                                                        setRegistrationData(newState);
                                                                    }
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-phone`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-birth-year`}>
                                                            Születési évszám*
                                                        </label>
                                                        <ValidatedInput compId={`registration-birth-year`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-birth-year`}
                                                                key={`registration-birth-year`}
                                                                placeholder={"1966"}
                                                                value={registrationData.birthYear}
                                                                onChange={(e) => {
                                                                    const value = e.target.value.trim();
                                                                    const reg = /^\d+$/;
                                                                    if ((value.length <= 4 && value.match(reg)) || !value) {
                                                                        const newState = { ...registrationData };
                                                                        newState.birthYear = value;
                                                                        setRegistrationData(newState);
                                                                    }
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-birth-year`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-settlement`}>
                                                            Lakóhelye (település)*
                                                        </label>
                                                        <ValidatedInput compId={`registration-settlement`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-settlement`}
                                                                key={`registration-settlement`}
                                                                placeholder={"Budapest"}
                                                                value={registrationData.settlement}
                                                                onChange={(e) => {
                                                                    const newState = { ...registrationData };
                                                                    newState.settlement = e.target.value;
                                                                    setRegistrationData(newState);
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-settlement`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-trip-id`}>
                                                            Táv
                                                        </label>
                                                        <FormSelect
                                                            id="registration-trip-id"
                                                            value={registrationData.tripId}
                                                            className={'select-line-height'}
                                                            onChange={(e) => {
                                                                const value = parseInt(e.target.value);
                                                                const newState = { ...registrationData };
                                                                newState.tripId = value;
                                                                if (value === 2 || value === 4 || value === 6) newState.time = 0;
                                                                setRegistrationData(newState);
                                                            }}
                                                        >
                                                            <option value={1}>Rigócska (12 km)</option>
                                                            <option value={2}>Apátkúti Trail (16 km)</option>
                                                            <option value={3}>Rigó félmaraton (21 km)</option>
                                                            <option value={4}>Spartacus Trail (24 km)</option>
                                                            <option value={5}>Rigó maraton (42 km)</option>
                                                            <option value={6}>Rigó Ultra Trail (68 km)</option>
                                                        </FormSelect>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-method`}>
                                                            Megtétel módja
                                                        </label>
                                                        <FormSelect
                                                            id="registration-method"
                                                            value={registrationData.method}
                                                            className={'select-line-height'}
                                                            onChange={(e) => {
                                                                const newState = { ...registrationData };
                                                                newState.method = parseInt(e.target.value);
                                                                setRegistrationData(newState);
                                                            }}
                                                        >
                                                            <option value={0}>futva</option>
                                                            <option value={1}>túrázva</option>
                                                        </FormSelect>
                                                    </FormGroup>
                                                </Col>
                                                <Col sm="6">
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-time`}>
                                                            Megtétel kívánt időszaka
                                                        </label>
                                                        <FormSelect
                                                            id="registration-time"
                                                            value={registrationData.time}
                                                            className={'select-line-height'}
                                                            onChange={(e) => {
                                                                const newState = { ...registrationData };
                                                                newState.time = parseInt(e.target.value);
                                                                setRegistrationData(newState);
                                                            }}
                                                        >
                                                            <option value={0}>nappali</option>
                                                            {(registrationData.tripId === 1
                                                                || registrationData.tripId === 3
                                                                || registrationData.tripId === 5)
                                                                && <option value={1}>éjszakai</option>}
                                                        </FormSelect>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <FormGroup>
                                                        <label htmlFor={`#registration-billing-address`}>
                                                            Számlázási cím*
                                                        </label>
                                                        <ValidatedInput compId={`registration-billing-address`} errors={errors}>
                                                            <FormInput
                                                                id={`registration-billing-address`}
                                                                key={`registration-billing-address`}
                                                                placeholder={`1035 Budapest, Bécsi út 135. 2. em. 2. a.`}
                                                                value={registrationData.billingAddress}
                                                                onChange={(e) => {
                                                                    const newState = { ...registrationData };
                                                                    newState.billingAddress = e.target.value;
                                                                    setRegistrationData(newState);
                                                                }}
                                                                invalid={errors.filter((e) => e.id === `registration-billing-address`).length > 0}
                                                            />
                                                        </ValidatedInput>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormCheckbox
                                                        id="registration-completion-without-reward"
                                                        checked={registrationData.completionWithoutReward}
                                                        onChange={(e) => {
                                                            const newState = { ...registrationData };
                                                            newState.completionWithoutReward = !registrationData.completionWithoutReward;
                                                            setRegistrationData(newState);
                                                        }}
                                                        inline
                                                    >
                                                        Teljesítés díjazás nélkül
                                                    </FormCheckbox>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                            <Row>
                                <Col>
                                    <p className="text-20 text-center mb-3">A fizetendő összeg: {getPayment()} Ft</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mb-[10px]">
                                    <Button onClick={wantsToRegister}>
                                        Regisztráció
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    Számla tulajdonos: Kolozsvári Viktor e.v.
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    Számlaszám: 12010855 01707755 00100007
                                </Col>
                            </Row>
                            <Row className="mb-[30px]">
                                <Col className="text-center">
                                    Közlemény: név + születési év + táv
                                </Col>
                            </Row>
                            {apiResponse.text &&
                                <p className={`text-center ${apiResponse.severity === "error" ? "text-red" : "text-green"}`}>
                                    {apiResponse.text}
                                </p>
                            }
                            {wantsToSubmit &&
                                <FormModal
                                    title={"Biztosan véglegesíti a regisztrációt?"}
                                    content={
                                        <p>
                                            A regisztrációval együtt elfogadja az{" "}
                                            <a href="http://rigokor.hu/wp-content/uploads/Adatkezelesi-nyilatkozat.pdf" target="_blank">
                                                adatkezelési nyilatkozatot
                                            </a>.
                                        </p>
                                    }
                                    noAction={() => setWantsToSubmit(false)}
                                    yesAction={async () => await register()}
                                />
                            }
                        </>
                }
            </Form>
        </Container>
    );
}
