import { React, useRef } from "react";
import { Button, Col, Container, Row } from "shards-react-fork";
import AHrefJavascript from "./AHrefJavascript";

export default function FormModal({
  yesAction = () => { },
  yesActionText = "Igen",
  noAction = () => { },
  noActionText = "Nem",
  title = "",
  content = <></>,
  separatorsNOTNeeded,
  special
}) {
  const bg = useRef(null);

  return (
    <div
      className="bg-black bg-opacity-40 w-full h-full fixed top-0 left-0 z-10"
      ref={bg}
      onClick={(e) => (e.target === bg.current ? noAction() : () => { })}
    >
      <div className="bg-white rounded-[10px] w-5/6 sm:max-w-[470px] absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] p-4">
        <Row>
          <Col>
            <p className="text-18">
              {title}
            </p>
          </Col>
        </Row>
        {!separatorsNOTNeeded && <Row className="bg-[#5A6169] bg-opacity-20 h-[1px] mt-[-1em] mb-4" />}
        <Row>
          <Col>{content}</Col>
        </Row>
        {!separatorsNOTNeeded && <Row className="bg-[#5A6169] bg-opacity-20 h-[1px] mt-[-0.5em] mb-4" />}
        <Container>
          <Row>
            <>
              <Col className="text-left ml-[-14px] flex items-center">
                <AHrefJavascript onClick={noAction}>{noActionText}</AHrefJavascript>
              </Col>
              <Col className="text-right mr-[-14px]">
                <Button onClick={yesAction}>{yesActionText}</Button>
              </Col>
            </>
          </Row>
        </Container>
      </div>
    </div>
  );
}
