import React from "react";

export function Loading() {
  return (
    <div>
      <div className="loadingio-spinner-rolling-pel4utqdlna">
        <div className="ldio-z7q7b8flzr">
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
