import {
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React } from "react";
import { Button } from "shards-react-fork";

export default function FormSuccess({ code, email, clearData, setApiResponse }) {
  return (
    <>
      <div className="flex items-center justify-center flex-wrap w-full absolute top-0 left-0 right-0 bottom-0">
        <div className="block">
          <div className="leading-[0] block w-full text-[190px] text-center text-[#007bff] mb-[0.2em]">
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
          <div className="block w-full text-center mt-0 text-24">
            <div className="max-w-[80%] mx-auto my-0">
              Regisztrációját sikeresen rögzítettük.
              <br />
              Sikeres regisztráció! A kódod: <b>{code}</b>. A regisztrációs adatokat elküldtük a {email} email címre.
            </div>
            <Button
              className="mt-[3em]"
              onClick={() => {
                clearData();
                setApiResponse({ severity: "success", text: "" });
              }}
            >
              Visszalépés a regisztrációs oldalra
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
