import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RegistrationPage from './pages/RegistrationPage';
import StationPage from './pages/StationPage';
import PostMessage from './components/PostMessage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<RegistrationPage />} />
        <Route path="/:station" element={<StationPage />} />
        <Route path="/stripe-success" element={<PostMessage message={"stripe-success"} />} />
        <Route path="/stripe-cancel" element={<PostMessage message={"stripe-cancel"} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
