import { React, useEffect, useState } from "react";
import ValidatedInput from '../components/ValidatedInput';
import Loading from '../components/Loading';
import { Button, Col, Container, Form, FormInput, Row } from "shards-react-fork";
import { useLocation } from "react-router-dom";
import { trip } from "../services/userService";
import NoRoutePage from "./NoRoutePage";

export default function StationPage() {
    const location = useLocation();

    const [stationData, setStationData] = useState({
        code: '',
        checkpoint: '',
        timestamp: '',
    });
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState({ severity: "success", text: "" });

    /* const paths = [
        '/kis-rigo-vendeglo',
        "/dobogokoi-kilato",
        "/pilis-teto",
        "/rezso-kilato",
        "/kainitz-forras",
        "/predikaloszek",
        "/utjelzo-tabla",
        "/sikaros-elagazas"
    ]; */

    const paths = [
        { checkpoint: "Kis Rigó vendéglő rajt", url: '/unorfcr8m3r4nvpuoq4a', },
        { checkpoint: "Dobogókői kilátó", url: '/pqerueh6wk38r3np93ef', },
        { checkpoint: "Pilis-tető", url: '/pywb00ru4ojicemmaqlc', },
        { checkpoint: "Rezső-kilátó", url: '/jzn09uvjezk80dc9xffm', },
        { checkpoint: "Kaintz-forrás", url: '/lti9aekvndghtby64b8y', },
        { checkpoint: "Prédikálószék", url: '/qm8nlmey1528fpee91f1', },
        { checkpoint: "Útjelző tábla", url: '/7nowwlkb6fbf6w2ggn4o', },
        { checkpoint: "Sikáros elágazás", url: '/gpjx6vu0v4m16vchz1iv', },
        { checkpoint: "Jenő-kunyhó", url: "/ky6gc2w3v2h2txghtxpt" },
        { checkpoint: "Ördögmalom-vízesés", url: "/qv8a89e9wacs3k4k92a4" },
        { checkpoint: "Fellegvár", url: "/8n5r3c9yjnuz97vm4az6" },
        { checkpoint: "Vízverés nyerge", url: "/r92r8tkkc7hgv64ku6af" },
        { checkpoint: "Kis Rigó vendéglő", url: '/9g4fyknzrwmk2x8nh6z9', },
        /* { checkpoint: "Útjelző tábla", url: '/ff4c9rwduqrnqj3ph5zq', },
        { checkpoint: "Dobogókői kilátó", url: '/47uzsy54yzhv5bt2verx', }, */
        { checkpoint: "Pap-rét", url: "/98eykayvyua7qaemc2j5" },
        { checkpoint: "Két-bükkfa-nyereg", url: "/u43sm6ue54pum4ezfngg" },
        { checkpoint: "Mária-pad", url: "/6jwaetz3ehaetrv8tnvs" },
        { checkpoint: "Rám-hegy", url: "/4qb8s86sm694t6whntkk" },
        { checkpoint: "Kis Rigó vendéglő cél", url: '/ocxlg18oy9v11d1uynri', },
    ];
    const currentPath = paths.find(path => path.url === location.pathname);

    useEffect(() => {
        if (!stationData.checkpoint && currentPath) {
            const newState = { ...stationData };
            newState.checkpoint = currentPath.checkpoint;
            setStationData(newState);
        }
    }, []);

    const DisplayTitle = () => currentPath?.checkpoint || "Ismeretlen";

    const validate = () => {
        const errors = [];

        if (stationData.code === "")
            errors.push({
                id: "station-code",
                message: "A kód kitöltése kötelező!",
            });
        if (stationData.code.length < 4)
            errors.push({
                id: "station-code",
                message: "A kódnak 4 karakterből kell állnia!",
            });

        setErrors(errors);
        return errors;
    }

    const submit = async () => {
        if (validate().length === 0) {
            setIsLoading(true);

            const timestamp = Math.floor(Date.now() / 1000).toString();
            const newState = { ...stationData };
            newState.timestamp = timestamp;
            setStationData(newState);
            setApiResponse(await trip(newState));

            setIsLoading(false);
        }
    }

    if (paths.map(p => p.url).includes(location.pathname))
        return (
            isLoading
                ? <Loading />
                :
                <Form className="station-page">
                    <Container>
                        <Form>
                            <Container>
                                <Row className="text-center mt-[2em] text-24 font-bold">
                                    <Col>
                                        {DisplayTitle()}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center mt-[100px] mb-[10px]">
                                        Kérjük adja meg a nevezéskor kapott kódot!
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-[25px]">
                                        <ValidatedInput compId={`station-code`} errors={errors}>
                                            <FormInput
                                                id={`station-code`}
                                                placeholder={`1a2b`}
                                                value={stationData.code}
                                                onChange={(e) => {
                                                    const value = e.target.value.trim();
                                                    if (value.length <= 4 || !value) {
                                                        const newState = { ...stationData };
                                                        newState.code = value;
                                                        setStationData(newState);
                                                    }
                                                }}
                                                invalid={errors.filter((e) => e.id === `station-code`).length > 0}
                                            />
                                        </ValidatedInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center mb-[10px]">
                                        <Button
                                            className="min-w-[200px] text-20"
                                            onClick={async () => await submit()}
                                        >
                                            <b>MEGADÁS</b>
                                        </Button>
                                    </Col>
                                </Row>
                                {apiResponse.text &&
                                    <Row>
                                        <Col className="text-center mt-[120px]">
                                            <b className={`text-30 ${apiResponse.severity === "error" ? "text-red" : "text-green"}`}>
                                                {apiResponse.severity === "success" ? "Helyes kód!" : apiResponse.text}
                                            </b>
                                        </Col>
                                    </Row>
                                }
                            </Container>
                        </Form>
                    </Container>
                </Form >
        );
    else return <NoRoutePage />;
}
