import { useEffect, useState } from "react";

export default function useNewWindow(
    sessionUrl,
    windowName,
    callback,
    width = window.innerWidth,
    height = window.innerHeight,
    top = 0,
    left = 0,
) {
    const [windowObjectReference, setWindowObjectReference] = useState(null);
    const [previousUrl, setPreviousUrl] = useState(null);
    const [messageEvent, setMessageEvent] = useState();

    const openWindow = () => {
        try {
            // window features
            const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;

            if (windowObjectReference === null || windowObjectReference === undefined || windowObjectReference.closed) {
                /* if the pointer to the window object in memory does not exist
                 or if such pointer exists but the window was closed */
                setWindowObjectReference(window.open(sessionUrl, windowName, strWindowFeatures));
            } else if (previousUrl !== sessionUrl) {
                /* if the resource to load is different,
                 then we load it in the already opened secondary window and then
                 we bring such window back on top/in front of its parent window. */
                const newWindowObjectReference = window.open(sessionUrl, windowName, strWindowFeatures);
                setWindowObjectReference(newWindowObjectReference);
                newWindowObjectReference.focus();
            } else {
                /* else the window reference must exist and the window
                 is not closed; therefore, we can bring it back on top of any other
                 window with the focus() method. There would be no need to re-create
                 the window or to reload the referenced resource. */
                windowObjectReference.focus();
            }
            // assign the previous URL
            setPreviousUrl(sessionUrl);
        } catch (e) {
            /* console.error(e); */
        }
    }

    useEffect(() => {
        if (sessionUrl) openWindow();
    }, [sessionUrl]);

    useEffect(() => {
        // add the listener for receiving a message from the popup
        window.addEventListener('message', receiveMessage, false);
        return () => window.removeEventListener('message', receiveMessage);
    }, []);

    const receiveMessage = event => {
        // Do we trust the sender of this message? (might be
        // different from what we originally opened, for example).
        if (event.origin !== window.location.origin) return;
        setMessageEvent(event);
    };

    useEffect(() => {
        if (messageEvent && windowObjectReference) {
            const { data } = messageEvent;

            if (messageEvent.source === windowObjectReference) {
                if (data === "stripe-success") callback("success");
                if (data === "stripe-cancel") callback("cancel");
            }
        }
    }, [messageEvent, windowObjectReference]);

    return windowObjectReference;
}