import { useEffect } from "react"
import Loading from "./Loading";

export default function PostMessage({ message }) {
    useEffect(() => {
        window.opener.postMessage(message, window.location.origin);
        window.close();
    }, []);

    return (
        <Loading />
    )
}