import { Button, Col, Container, Row } from "shards-react-fork";

export default function NoRoutePage() {
    return (
        <Container>
            <Row className="text-24 text-center mt-48 mb-8">
                <Col>
                    <div>
                        <p>Ez az oldal nem található.</p>
                        <a href="/">
                            <Button>
                                Vissza a regisztrációra
                            </Button>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}