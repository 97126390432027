import axios from "axios";

const origin = window.location.origin;
const apiUri = 
    `${origin.includes("test") || origin.includes("localhost")
        ? "http://rigokor.test"
        : process.env.NODE_ENV === "production"
            ? "https://azadatbazismasholvan.rigokor.hu"
            : "https://azadatbazismasholvan.rigokor.hu"}/api/`;

const headers = {
    "Content-Type": "application/json",
    'Accept': 'application/json'
}

export default async function apiRequest(uri, data) {
    const apiResponse = {
        severity: "success",
        text: ""
    };

    const genericErrorText = "Hiba történt az oldal működése közben. Elnézést kérünk a kellemetlenségért.";

    await axios.post(apiUri + uri, data, { headers: headers })
        .then(response => {
            const success = response?.data?.success;
            const error = response?.data?.error;
            if (success) apiResponse.text = success;
            else if (error) {
                apiResponse.severity = "error";
                apiResponse.text = error;
            }
            else {
                apiResponse.severity = "error";
                apiResponse.text = genericErrorText;
            }
        })
        .catch(error => {
            apiResponse.severity = "error";
            if (error?.response?.data) {
                // client received an error response (5xx, 4xx)
                apiResponse.text = error?.response?.data?.error || genericErrorText;
            } else if (error.request) {
                // client never received a response, or request never left
                apiResponse.text = genericErrorText;
            } else {
                // anything else
                apiResponse.text = genericErrorText;
            }
        });

    return apiResponse;
}